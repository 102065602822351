
#userDropdownMobile .mobile_icon-1 {
  fill: $landing-text-color !important;
}

#userDropdownMobile .mobile_icon-2 {
  fill: $primary-text-color !important;
} 

#userDropdownMobile svg {
  overflow: hidden;
  transition-duration: 0.8s;
  transition-property: transform;
  width: 25px !important;
  height: 25px !important;
}

.active-mobile-menu svg{
  transform: rotate(133deg);
  -webkit-transform: rotate(133deg);
}

@media (min-width: 1025px) {
  .coursePreview_Actions {
      background-position: -4px center !important;
      background-repeat: no-repeat !important;
      position: relative;
      padding-right: 4px;
      // &:after {
      //     position: absolute;
      //     content: '';
      //     width: 4px;
      //     height: 100%;
      //     background: transparent linear-gradient(192deg, #ffffff3c 0%, #4e4e4ee2 76%, #444444 100%) 0% 0% no-repeat padding-box;
      //     right: 0;
      //     top: 0;
      // }
  }
}

@media (min-width: 992px) and (max-width: 1024px) {
  .coursePreview_Actions {
      min-height: 348px !important;
  }
}

@media (max-width: 991px) {
  .coursePreview {
      flex-direction: column !important;
      .coursePreview_ActionsCover {
        .coursePreview_ActionsInfo {
          padding: 0 20px 20px 20px !important;
          h1 {
            font-size: 24px !important;
          }
  
          button.play {
            font-size: 18px !important;
          }
  
          button {
            padding: 0 29px !important;
            height: 50px !important;
            line-height: 50px !important;
          }
        }
      }
  
      .coursePreview_Subjects {
        min-width: 0px !important;
        max-width: 100% !important;
        width: 100% !important;
        height: auto !important;
        overflow: auto !important;
        .title {
          font-size: 18px !important;
        }
        .coursePreview_Items {
          position: relative !important;
        }
        .coursePreview_Items .card {
          .card-header {
            padding: 0 2.25rem !important;
          }
        }
      }
    }

    .pageContainer .coursePreview {
      margin-top: 0;
    }
  
    .content {
      padding-top: 0 !important;
    }

    .navbarMyCourses {
      border-top: 0;
      margin-top: 4px;
    }

  .landing.noCourse {
    height: auto !important;
  }
}

@media (min-width: 577px) and (max-width: 991px) {
  .coursePreview_Actions, .coursePreview_ActionsCover {
    min-height: 400px !important;
  }

  .coursePreview_Actions.iframe-active-iframe, .coursePreview_ActionsCover.iframe-active-iframe {
      min-height: 308px !important;
  }
}

@media (max-width: 576px) {
  //mobile
  .header {
    height: 80px !important;
    
    #kt_header_search {
      padding: 0 24.38px;
    }
    
    .search-icon-header svg {
      width: 22px !important;
      height: 22px !important;
    }

    .changeLanguage {
      font-size: 16px;
      font-weight: 500;
    }
  }

  .logo {
    max-width: 162px;
  }

  .login-wrapper {
    padding: 0 !important;
  }
  
  .login-image {
    margin-top: 55px !important;
  }

  .login-page {
    padding-left: 15px !important;
    padding-right: 15px !important;

    .login-wel-message {
      font-size: 20px;
    }

    .login-message {
      font-size: 32px;
      line-height: 41px;
      font-weight: 500;
    }

    .login-gray-message {
      font-size: 16px;
      line-height: 24px;
    }

    .title-login-form {
      font-size: 24px;
      color: $primary-text-color;
    }

    .forgot-password-text, .login-footer {
      font-size: 14px !important;
      font-weight: 500 !important;
      opacity: 1 !important;
    }

    .login-footer {
      padding: 30px 0;
    }

    .error-alert {
      font-size: 12px;
    }
  }

  #kt_header_menu_mobile_toggle,
  #userDropdown {
    display: none !important;
  }

  #userDropdownMobile {
    width: 30px;
    display: flex !important;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .navbarMyCourses {
    overflow-x: scroll;
  }

  .trainerContainer {
    flex-direction: column;
    padding: 15px 30px !important;
    .trainerName {
      font-size: 18px !important;
      min-width: 130px;
    }
    p {
      margin: 15px 0 !important;
      font-size: 18px !important;
      line-height: 29px !important;
    }
  }

  .myCourse_overview {
    h2 {
      font-size: 24px !important;
    }

    p,
    ul {
      font-size: 16px !important;
      font-weight: 400;
    }

    .myCourse_FAQItems {
      .card {
        margin-bottom: 10px !important;
        button {
          font-size: 16px !important;
        }
        .card-body {
          font-size: 14px;
        }
      }
    }
  }

  .allCourses {
    .navbarMyCourses {
      margin: 0 !important;
    }
    .allCoursesNoCourse {
      margin-top: 0 !important;
    }
  }

  .mobile-menu {
    display: flex !important;
    a.selectedWithBorder::after {
      width: 65% !important;
      left: -15% !important;
    }
  }

  .certificate {
    padding: 0 !important;
  }

  .indicator-progress {
    font-size: 14px;
    color: white;
  }
  #kt_sign_in_submit {
    width: 100% !important;
  }
  .buttons-div {
    flex-direction: column !important;
  }
  .marketIcons {
    display: flex !important;
    align-items: center !important;
    margin-top: 30px !important;
    margin-bottom: 30px !important;

    .marketIcon {
      svg {
        height: 46px !important;
        max-width:  156px !important;
        max-height: 46px !important;
        border: 3px solid $primary-text-color;
      }
    }
  }

  .allCoursesGrid {
    margin-top: 35px !important;
  }
  .landing {
    h1 {
      font-size: 32px !important;
      line-height: 41px;

      .logo {
        max-width: 125px;
        margin: -10px 14px 0 0 !important;
      }
  
      // span.wel-text {
      //   display: block;
      // }
    }

    .login-gray-message {
      font-size: 16px;
      margin: 10px 0 0 !important;
      line-height: 20px;
    }
  }

  .navbarMyCourses .navbarMyCourses_Item {
    font-size: 16px;
  }

  .profile-box .col-sm-6 {
    width: 50%;
  }

  .profile-box{
    margin-bottom: 49px !important;
    .user-name-pro {
        font-size: 15px;
    }
  }

  .title-login-form, .profile-title {
    font-size: 24px !important;
    color: $landing-text-color;
  }

  .coursePreview_Actions, .coursePreview_ActionsCover {
    min-height: 252px !important;
  }

  .coursePreview_Actions.iframe-active-iframe, .coursePreview_ActionsCover.iframe-active-iframe {
    min-height: 184px !important;
  }
  .allCoursesGrid {
    margin-top: 23px !important;
  }
}

